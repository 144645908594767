::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #e4e2e2;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(148, 147, 147);
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(119, 118, 118);
}

.MuiTableRow-root td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.MuiTableRow-root td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* Landing  Page */

@font-face {
    font-family: 'klavika';
    src: url("./assets/fonts/Klavika-Bold.eot");
    src: url("./assets/fonts/Klavika-Bold.woff") format("woff"), url("./assets/fonts/Klavika-Bold.ttf") format("truetype"), url("./assets/fonts/Klavika-Bold.svg#menu-button-font") format("svg");
    font-weight: 700;
}

@font-face {
    font-family: 'blender-book';
    src: url("./assets/fonts/Blender-Book.eot");
    src: url("./assets/fonts/Blender-Book.woff") format("woff"), url("./assets/fonts/Blender-Book.ttf") format("truetype"), url("./assets/fonts/Blender-Book.svg#menu-list-font") format("svg");
    font-weight: 400;
}

.main {
    background-image: url("./assets/images/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.logo-synesis {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100px;
}

.content {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-quantum {
    width: 250px;
    margin-top: 130px;
    margin-bottom: 40px;
}

.img-rewards {
    box-shadow: 0 4px 6px 6px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    max-width: 1000px;
    width: 100%;
}

.wallet-adapter-button {
    margin-top: 20px;
}

.btn, .content .wallet-adapter-button {
    background-image: url("./assets/images/play-button.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: transparent;
    /*width: 100%;*/
    padding: 20px 44px;
    border: none;
    font-size: 22px;
    font-family: 'klavika', Arial, sans-serif;
    text-transform: uppercase;
    color: #c1ebf9;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.main-button:hover .MuiButton-label, .small-button:hover .MuiButton-label {
    color: white;
}

.content .wallet-adapter-button:hover {
    background-image: url("./assets/images/play-button.png") !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: transparent;
    color: white;
}

.btn-discord {
    display: block;
    position: relative;
    margin-top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 264px;
    bottom: 20px;
    padding: 12px 17px 12px 60px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 12px;
    color: #fff;
    font-family: 'klavika', Arial, sans-serif;
    font-size: 22px;
    text-decoration: none;
    text-transform: uppercase;
}

.btn-discord:before {
    content: "";
    width: 34px;
    height: 34px;
    position: absolute;
    bottom: 18px;
    left: 14px;
    background-image: url("./assets/images/discord.png");
    background-size: contain;
}

.btn-discord span {
    display: block;
}

.btn-discord span:first-child {
    font-size: 17px;
    text-transform: none;
    font-family: 'blender-book', Arial, sans-serif;
}

.text {
    display: block;
    max-width: 675px;
    margin-bottom: 80px;
    font-size: 36px;
    font-family: 'klavika', Arial, sans-serif;
    text-align: center;
    color: white;
}

.btn-container {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 30px;
    margin-bottom: 60px;
}

.btn-container .btn {
    text-transform: none;
}

#fadein {
    text-align: center;
}

#fadein .btn {
    padding: 20px 110px;
}

.input-container {
    text-align: center;
    margin-bottom: 80px;
}

.input-container .text {
    margin-bottom: 15px;
}

.input-container .input {
    background-color: transparent;
    padding: 10px 16px;
    border: 2px solid #FC445C;
    border-radius: 10px;
    width: 100%;
    max-width: 200px;
    color: #fff;
    font-size: 22px;
    font-family: 'klavika', Arial, sans-serif;
}

@media all and (min-width: 360px) {
    .logo-quantum {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .btn {
        font-size: 28px;
        padding: 19px 59px;
        width: auto;
    }

    .btn-discord {
        left: 12px;
        transform: none;
    }
}

@media all and (min-width: 768px) {
    .btn-container {
        justify-content: space-between;
        row-gap: unset;
    }

    .btn-discord {
        position: absolute;
        bottom: 20px;
    }
}

/* Moje dodatki do stylu */

.sub-container {
    margin-bottom: 40px;
}

h1, h2 {
    color: white;
}

h1.info {
    width: 630px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.unstake-info {
    color: white;
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 40px;
}

h1.install-info {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.MuiBox-root {
    text-align: center;
    margin-bottom: 40px;
}

.main-button .MuiButton-label {
    min-width: 320px;
    padding: 20px 30px;
    border: none;
    font-size: 22px;
    font-family: 'klavika', Arial, sans-serif;
    text-transform: uppercase;
    color: #c1ebf9;
    cursor: pointer;
}

.small-button .MuiButton-label {
    min-width: 160px;
    padding: 10px 10px;
    border: none;
    font-size: 16px;
    font-family: 'klavika', Arial, sans-serif;
    text-transform: uppercase;
    color: #c1ebf9;
    cursor: pointer;
}

.main-button:disabled .MuiButton-label,
.main-button[disabled] .MuiButton-label {
    color: #666666 !important;
}

.main-button:disabled,
.main-button[disabled] {
    opacity: 0.5;
}

#loading {
    position: fixed;
    display: inline-block;
    width: 70px;
    height: 70px;
    border: 6px solid rgba(255, 255, 255, .3);
    border-radius: 50%;
    border-top-color: #fff;

    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);

    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;

    top: 50%;
    left: 50%;

}

/* TODO: MOVE CSS TO SCSS */
@keyframes spin {
    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
    }
}

/* Unity Loader */

#unity-container {
    display: flex;
    user-select: none;
    justify-content: center;
    /*align-items: center;*/
    background-image: url("./assets/images/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

#unity-canvas {
    width: 100%;
}

#unity-user {
    position: absolute;
    left: 50%;
    top: 20px;
    width: 200px;
    height: 10px;
    padding: 10px 20px;
    color: #ff9800;
    border: 4px solid #ff9800;
    border-radius: 15px;
    z-index: 100;
    font-weight: bold;
    font-family: arial;
    font-size: 20px;
    transform: translateX(-50%);
    background: transparent;
}

#unity-fullscreen-button {
    display: none;
    user-select: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 20px;
    min-width: 120px;
    height: 10px;
    padding: 10px 30px;
    border-radius: 15px;
    background: #ff9800;
    text-align: center;
    color: black;
    line-height: 28px;
    z-index: 100;
    font-weight: bold;
    font-family: arial;
    transform: translateX(-50%);
}

#unity-fullscreen-button:hover {
    cursor: pointer;
    background: #ffb84d;
}

#unity-loader-image {
    display: none;
    position: absolute;
    width: 250px;
    height: 250px;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}

#unity-progress-border {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 30%;
    transform: translate(-50%, -50%);
    width: 383px;
    height: 59px;
    text-align: center;
    color: white;
    line-height: 28px;
    background-image: url("./assets/images/bar.png");
    border-radius: 15px;
}

#unity-progress {
    width: 237px;
    height: 33px;
    background: rgba(113, 128, 123, 0.6);
    margin: 13px 73px;
}

#start-game {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

#start-game-text {
    color: white;
    text-decoration: underline;
}

#start-game-text:hover {
    cursor: pointer;
}

.toolbar {
    display: flex;
    flex-direction: row;
    border: 2px solid grey;
    background: darkgrey;
    margin: 15px;
    padding: 7px;

}

.toolbar .label {
    margin: 15px;
}

.toolbar button {
    margin: 2px 6px;
}